import React, { useState, useEffect } from "react";
import { FiArrowLeft, FiArrowRight, FiX, FiHome } from "react-icons/fi";
import { motion, MotionConfig } from "framer-motion";
import { twMerge } from "tailwind-merge";
import api from "../utils/api";

export const Invite = () => {
  return (
    <section className="grid h-screen w-screen grid-cols-12 border border-neutral-700 bg-neutral-900 text-neutral-50">
      <Left />
      <Right />
    </section>
  );
};

const CustomAlert = ({ message, onClose, type = "success" }) => (
  <div className="fixed left-4 right-4 top-4 z-50 mx-auto max-w-md transform rounded-lg shadow-lg transition-all duration-500 ease-in-out md:right-4 md:left-auto md:w-96">
    <div className={`${type === "error" ? "bg-red-500" : "bg-blue-500"} flex items-start justify-between rounded-lg p-4 text-white`}>
      <div className="flex-1 pr-3">
        <h3 className="mb-1 font-semibold">{type === "error" ? "Error!" : "Success!"}</h3>
        <p className="text-sm break-words">{message}</p>
      </div>
      <button 
        onClick={onClose}
        className={`flex-shrink-0 rounded-full p-1 transition-colors ${
          type === "error" ? "hover:bg-red-600" : "hover:bg-blue-600"
        }`}
      >
        <FiX className="h-5 w-5" />
      </button>
    </div>
  </div>
);

const SubmittedState = () => (
  <div className="flex w-full flex-col items-center gap-4 border-t border-neutral-700 bg-neutral-950 p-6 text-center">
    <p className="text-neutral-400">You've already submitted your application</p>
    <motion.a
      href="/"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="flex items-center gap-2 rounded-full bg-blue-500 px-6 py-2 text-sm font-medium text-white transition-colors hover:bg-blue-600"
    >
      <FiHome className="text-lg" />
      Go Back Home
    </motion.a>
  </div>
);

const Left = () => {
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    type: "success"
  });
  
  useEffect(() => {
    const hasSubmitted = localStorage.getItem('hasSubmittedInviteForm');
    if (hasSubmitted) {
      setAlert({
        show: true,
        message: "Thank you for joining our exclusive early access list. We'll be in touch soon!",
        type: "success"
      });
    }
  }, []);

  return (
    <div className="col-span-12 flex flex-col justify-around border-r border-neutral-700 md:col-span-6">
      <div className="px-6 py-6 md:px-12 md:py-24">
        <h1 className="text-3xl uppercase leading-tight md:text-5xl md:leading-tight">
          <span className="text-blue-500">Exclusive Access </span>
          to AI-Powered Visa Jobs
        </h1>
      </div>
      <InviteForm setAlert={setAlert} />
      {alert.show && (
        <CustomAlert 
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert({ ...alert, show: false })}
        />
      )}
    </div>
  );
};

const InviteForm = ({ setAlert }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await api.post('/early-access/submit', formData);
      
      localStorage.setItem('hasSubmittedInviteForm', 'true');
      setAlert({
        show: true,
        message: "Thank you for joining our exclusive early access list. We'll be in touch soon!",
        type: "success"
      });
      setFormData({ name: '', email: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setAlert({
        show: true,
        message: error.response?.data?.error || "Failed to submit request. Please try again.",
        type: "error"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const hasSubmitted = localStorage.getItem('hasSubmittedInviteForm');

  if (hasSubmitted) {
    return <SubmittedState />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="relative z-30 flex w-full flex-col gap-3 border-t border-neutral-700 bg-neutral-950 p-6"
    >
      <motion.input
        type="text"
        placeholder="Enter your full name"
        required
        value={formData.name}
        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
        className="w-full rounded-lg bg-neutral-800 p-3 text-sm text-white placeholder-neutral-500 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500"
        whileFocus={{ scale: 1.01 }}
      />
      <motion.input
        type="email"
        placeholder="Enter your email"
        required
        value={formData.email}
        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
        className="w-full rounded-lg bg-neutral-800 p-3 text-sm text-white placeholder-neutral-500 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500"
        whileFocus={{ scale: 1.01 }}
      />
      <motion.button
        type="submit"
        disabled={isSubmitting}
        whileHover={{ scale: isSubmitting ? 1 : 1.02 }}
        whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
        className="group flex items-center justify-center gap-1.5 rounded-lg bg-blue-500 px-4 py-3 text-sm font-medium text-white transition-all hover:bg-blue-600 disabled:opacity-50"
      >
        <span>
          {isSubmitting ? 'Submitting...' : 'Join Early Access (100 spots)'}
        </span>
        <FiArrowRight className="transition-all group-hover:translate-x-1" />
      </motion.button>
    </form>
  );
};

const Right = () => {
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIdx((prevIdx) => (prevIdx === CONTENT.length - 1 ? 0 : prevIdx + 1));
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="col-span-12 flex h-full flex-col justify-between md:col-span-6">
      <div className="relative flex-1 overflow-hidden">
        {CONTENT.map((c, itemIdx) => {
          return (
            <motion.div
              initial={false}
              animate={{
                opacity: idx === itemIdx ? 1 : 0,
                y: idx === itemIdx ? 0 : 24,
                filter: idx === itemIdx ? "blur(0px)" : "blur(2px)",
              }}
              transition={{
                ease: "easeInOut",
                duration: 0.3,
              }}
              style={{
                pointerEvents: idx === itemIdx ? "all" : "none",
              }}
              className="absolute inset-0 z-10 grid place-content-center space-y-3 px-6 text-base font-light leading-relaxed text-neutral-400 md:px-12 md:text-lg"
              key={itemIdx}
            >
              {c.content}
            </motion.div>
          );
        })}
        <span className="pointer-events-none absolute -right-0 bottom-0 z-0 text-7xl text-neutral-800">
          {idx + 1}/{CONTENT.length}
        </span>
      </div>
      <Buttons idx={idx} setIdx={setIdx} />
    </div>
  );
};

const Buttons = ({ idx, setIdx }) => {
  return (
    <div className="relative grid h-[57px] grid-cols-2 border-t border-neutral-700">
      <ShiftButton
        onClick={() => {
          setIdx((pv) => (pv === 0 ? CONTENT.length - 1 : pv - 1));
        }}
        topDivClasses="bg-neutral-900"
        bottomDivClasses="bg-neutral-950"
      >
        <FiArrowLeft className="mx-auto text-xl" />
      </ShiftButton>
      <ShiftButton
        topDivClasses="bg-neutral-900"
        btnClasses="border-neutral-700 border-l"
        bottomDivClasses="bg-neutral-950"
        onClick={() => {
          setIdx((pv) => (pv === CONTENT.length - 1 ? 0 : pv + 1));
        }}
      >
        <FiArrowRight className="mx-auto text-xl" />
      </ShiftButton>
    </div>
  );
};

const ShiftButton = ({
  onClick,
  children,
  btnClasses,
  topDivClasses,
  bottomDivClasses,
}) => {
  return (
    <MotionConfig
      transition={{
        ease: "circOut",
        duration: 0.25,
      }}
    >
      <motion.button
        initial="initial"
        whileHover="hovered"
        className={twMerge(
          "relative overflow-hidden transition-colors",
          btnClasses
        )}
        onClick={onClick}
      >
        <motion.div
          variants={{
            initial: {
              y: "0%",
            },
            hovered: {
              y: "-100%",
            },
          }}
          className={twMerge(
            "grid h-full place-content-center bg-neutral-950",
            topDivClasses
          )}
        >
          {children}
        </motion.div>
        <motion.div
          variants={{
            initial: {
              y: "100%",
            },
            hovered: {
              y: "0%",
            },
          }}
          className={twMerge(
            "absolute inset-0 grid h-full place-content-center",
            bottomDivClasses
          )}
        >
          {children}
        </motion.div>
      </motion.button>
    </MotionConfig>
  );
};

const CONTENT = [
  {
    content: (
      <>
        <p>
          <span className="text-white">Welcome to the future of job hunting 👋</span>{" "}
          Get early access to our{" "}
          <span className="text-blue-500">
            AI-powered visa-sponsored jobs platform
          </span>
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <p>
          <span className="text-white">AI Job Match Score:</span> Our advanced AI analyzes your profile and matches you with the perfect visa-sponsored positions.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <p>
          <span className="text-white">Smart Applications Tracker:</span> Keep track of all your applications, interviews, and follow-ups in one place.
        </p>
      </>
    ),
  },
  {
    content: (
      <>
        <p>
          <span className="text-white">AI CV & Cover Letter Writer:</span> Generate tailored applications that highlight your strengths and increase your chances of success.
        </p>
      </>
    ),
  },
];

export default Invite;