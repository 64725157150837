export default function Error404() {
    return (
      <>
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-green-600">This APP is no longer maintained :(</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">But, we have something even better</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Cick on the button below to visit our new Ukworkx APP at https://app.ukworkx.com</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="https://app.ukworkx.com"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Go to Ukworkx APP
              </a>
            </div>
          </div>
        </main>
      </>
    )
  }
  